<template>
  <div class="container">
    <!-- 返回头部 -->
    <el-backtop></el-backtop>

    <!-- 头部区域 -->
    <Header :headerData={} />

    <div class="paper">
      <el-image v-for="(p, i) in cover" :key="i" 
        :src=p 
        alt=""
        :preview-src-list="all_img"></el-image>
      <!-- <el-image :src=cover[1] alt=""></el-image> -->
    </div>

    <div class="panel">
      <!-- preface -->
      <!-- <div class="box">
        <div class="img">
          <img src="../assets/img/ysa/i1_preface.jpg" alt="">
        </div>
        <h2 style="text-align:center; font-size:22px">Preface</h2>
        <div class="author" style="text-align:center; font-size:12px;color:black;">
          {{preface_note}}
        </div>
        <p class="abstract" v-for="i in 4" :key=i>{{preface[i-1]}}</p>
      </div> -->
      <!-- articles -->
      <div class="box"
        v-for="(p, i) in paper" :key="i"
        >
        <div class="img" >
          <img :src=article_img[i] alt="">
        </div>
        <h2>{{p.title}}</h2>        
        <div class="author-dl">
          <p class="author">{{p.author}}, 2022/10</p>
          <el-button type="primary" icon="el-icon-download" size="mini" el-icon--right style="padding:0 7px;" @click="clickDownLoadPdf(i)">PDF</el-button>
        </div>
        <p class="abstract">{{p.abstract}}</p>
        <a :href="'https://doi.org/' + p.DOI"><img :src="'https://zenodo.org/badge/DOI/' + p.DOI + '.svg'" alt="DOI"></a>
      </div>
    </div>

    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import Footer from "../../components/Footer.vue";
  // import Swiper JS
  // import Swiper from 'swiper';
  // // import Swiper styles
  // import 'swiper/css';
  // const swiper = new Swiper('.swiper', {
  //   // Optional parameters
  //   direction: 'vertical',
  //   loop: true,

  //   // If we need pagination
  //   pagination: {
  //     el: '.swiper-pagination',
  //   },

  //   // Navigation arrows
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev',
  //   },

  //   // And if we need scrollbar
  //   scrollbar: {
  //     el: '.swiper-scrollbar',
  //   },
  // });
  

  export default {
    name: "Home",
    data() {
      return {
        paper : [
          {
            DOI: "10.5281/zenodo.10002942",
            title: "A Comparative Analysis Based on LeNet5, AlexNet, VGG16, and ResNet18 in Plankton Classification", 
            author: "Spark Zhao et al.",
            abstract:"Plankton is the fundamental component of the marine ecosystem and plays important roles in matter circulation and energy flow of the marine food chain. Outbreaks and invasions of plankton can also have a serious impact on the marine environment. Therefore, its observation and identification are not only fundamental to scientific research to understand marine resources, biodiversity, and the impact of climate change on the ecosystem, but also an essential tool in modern ocean management. In recent years, convolution neural network (CNN) has been actively applied in the automatic classification of plankton from their images for measurement and statistics. In this experiment, we test Plankton200, a dataset that contains 170 images and 4 categories of plankton, against AlexNet, LeNet5, VGG16, and ResNet18 respectively to test their ability to classify the images. We conclude that the training effect of ResNet18 is the best and the accuracy rate is 81.95. In addition, we perform ablation experiments on the batch size and learning rate of them. When the batch size is 8 and the learning rate is 0.01, its accuracy is the highest."
          }, 
          {
            DOI: "10.5281/zenodo.8048448",
            title: "How Neurotransmitters Affect Movement of Flatworms",
            author: "Marvin Xu",
            abstract:"Serotonin is a neurotransmitter that has a profound impact on mood and hunger. It is a typical “happy hormone” that is used to treat depression and can also suppress appetite. The study is set to determine the difference in foraging behavior of two planarians, Paucumara sp. (marine water species) and Dugesia sp. (freshwater species) in the presence and absence of Serotonin. The two species are placed in two different concentrations of serotonin, and pork liver paste is used as a food source to attract the two species. Their corresponding foraging behavior is observed and noted. The results of the experiment showed that there is a positive correlation between foraging behavior and concentration of serotonin for Paucumata sp. but not for Dugesia sp."
          },
          {
            DOI: "10.5281/zenodo.8048463",
            title: "Experiment on the Phototaxis Behavior of Barnacle Larvae", 
            author: "Harry Tong",
            abstract: "To many larvae of biofouling organisms, phototaxis behavior was crucial for their survival. Research on the phototaxis behavior of many of them was done, however the comparison of phototaxis behavior of two similar species was not enough. This experiment compares the phototaxis behavior of the larvae of subtidal acorn barnacle in the genus Balanus collected from Dapeng Shenzhen and the larvae intertidal stalk barnacle Capitulum mitella towards six different wavelengths of light source. The result of this experiment showed that despite being different species, the two specimens share similar positive phototaxis behavior towards blue and green light, which supports the idea that when two closely related species living in highly contrasting environment, despite living in different environment, the larval phototaxis behavior will also be similar."
          },
          {
            DOI: "10.5281/zenodo.8048471",
            title: "Identifying The Foraging Behavior of Flatworms During Regeneration", 
            author: "Authur Zeng et al.",
            abstract:"Flatworms are famous for their regeneration ability or binomial diffusion. Because the feeding behavior of flatworms after regeneration remains uninvestigated, we conducted experiments on the foraging behavior of freshwater Dugesia flatworm specimens after regeneration. The flatworms were placed in petri dishes with freshwater after dissecting. On the 3rd and 5th days, food was placed in the petri dish, and then the behavior of flatworms was recorded for 30 minutes. The results of both our experiments show that dissected flatworms do not have foraging behavior, which was similar to a study on marine polyclad flatworm."
          },
          {
            DOI: "10.5281/zenodo.8048479",
            title: "Flatworm Regeneration and Reactionary Behavior Towards Food Source During Different timespans", 
            author: "Josie Liu",
            abstract:"Flatworms are known for their ability to regenerate due to their pluripotent stem cells. Newer organs and systematic life functions can be produced from the remnants of the flatworm and eventually form an entirely new flatworm. However, previous studies have indicated that regeneration was impossible without brain tissue. This implies that in flatworm regeneration, only the head part of the flatworm that contains a brain-like structure and nervous tissue could regenerate, and the body portion without the brain tissues would eventually dissolve or die. However, in my experiment, it is discovered that the body could also regenerate regardless of whether there is brain tissue present in the part of the body severed. Additionally, the studies also indicated that the regenerated portion would lack the necessary life functions. However, after 6 days of regeneration, the sliced flatworm, or at least its head, was able to target the food source and reach it quite well. Therefore, my results do not support the theory that flatworms without brain tissue were not able to produce organs with vital life functions."
          },
          {
            DOI: "10.5281/zenodo.8048488",
            title: "Effect on Flatworms Foraging Behavior Due to Change in Salinity", 
            author: "Rachael Zhou",
            abstract:" Salinity is one of the key environmental factors that constitute the biogeographical boundary of aquatic organisms, and affects the distribution, abundance and behavior of aquatic organisms. However, little is known about the relationship between salinity changes and foraging behavior of aquatic organisms. In this research, I chose the marine flatworms Paucumara sp.as my research target to study the influence of salinity changes on the foraging behavior. In salt water with a salinity of 20ppt, the foraging behavior of the flatworms was comparable to the seawater control (30ppt). In salt water with a salinity of 10ppt, the marine flatworms show a small amount of twitching and crouching behavior during foraging, and move slowly comparing to the movement speed in seawater. But eventually they succeeded in finding food. In salt water with a salinity of 5ppt, the marine flatworms have no desire to feed and move slowly in a crouched form. In salt water with a salinity of 1ppt, the flatworms did not move and remained crouched, without any foraging behavior observed. In salt water with a salinity of 10, 5, 1ppt, marine flatworms can feed when being placed near food. Overall, decrease in salinity has a significant effect on the foraging process."
          },
          {
            DOI: "10.5281/zenodo.8052488",
            title: "Starved Flatworm VS Fed Flatworm Time to Find Food and Behavior Throughout the Time Period", 
            author: "Zoe Kung",
            abstract:"Flatworms come under the phylum Platyhelminthes with the diversity of 12,821 species. I collected the marine water flatworm Paucumara sp. from a lab culture and performed a flatworm feeding experiment, using starved and fed flatworm to discover how long it takes for the flatworms to find food, and their feeding behavior. I found that the starved flatworms are mostly eager to search and look for food while fed flatworm tend to just ignore the food and wander around at a random path. My results suggested that the fed flatworms were not eager to find food while the starved one were more eager, which is the general behavior of animals."
          },
          {
            DOI: "10.5281/zenodo.8048498",
            title: "Effects of Glutamate and Acetylcholine on Foraging Behaviors of Dugesia sp.",
            author: "Rose Xu",
            abstract:"Planarians are one of the most primitive groups of organisms to have developed acentral nervous system, being a useful model for neurological research. This experiment focused on the foraging behavior of Dugesia sp., a freshwater planarian under the treatment of different concentrations of exogenous neurotransmitters glutamate and acetylcholine. The average velocities of planarians showed dose-related decreasing trends when acetylcholine was applied, while an overall increase was observed in all glutamate groups. Thus, the results suggested the role of the two neurotransmitters in regulating planarian motility, meanwhile providing insights towards the evolution of vertebrates."
          },
        ],

        cover: [
          require('../../assets/img/ysa/v1i1/i1_cover.jpg'),
          require('../../assets/img/ysa/v1i1/i1_back.jpg'),
        ],

        article_img:[
          require('../../assets/img/ysa/v1i1/i1a8.jpg'),
          require('../../assets/img/ysa/v1i1/i1a1.jpg'),
          require('../../assets/img/ysa/v1i1/i1a2.jpg'),
          require('../../assets/img/ysa/v1i1/i1a3.jpg'),
          require('../../assets/img/ysa/v1i1/i1a4.jpg'),
          require('../../assets/img/ysa/v1i1/i1a5.jpg'),
          require('../../assets/img/ysa/v1i1/i1a6.jpg'),
          require('../../assets/img/ysa/v1i1/i1a7.jpg'),
        ],
        
        all_img : [],

        preface: [
          'Charles Darwin is considered one of the most influential scientists in the field of biology. He begun working on the topic of evolution in 1837, drawn up "some short notes" after five years, enlarged these into a sketch in 1844, and finally published the masterpiece “On the Origin of Species” on 24 November 1859. Darwin’s experience has three messages. Firstly, curiosity toward nature, as the development of his evolutionary theories started with his long observations on a wide range of organism species. Secondly, successive concentration and passion on a topic, as it took him more than 20 years to perfect his evolutionary theories. Finally, good scientific works should be supported by a body of scientific evidence.',
          'It is therefore our wish that young students would express curiosity toward nature, eager to observe, dare to ask why, and more importantly stay on the topic and find out the answer. We see The Young Scientist as a platform for students to be young scientists. As a group of active scientific researchers, we hope that by publishing decent scientific works conducted by students, we can encourage more of you to join us, doing interesting experiments, using new technologies and help us to extract novel but critical findings that would have been failed to be discovered using traditional methods.',
          'We believe the greatest scientists of the next century are among you!',
        ],
        preface_note: "Editorial Board of The Young Scientist",
      };
    },    
    components: {
      Footer,
      Header,
      // SectTurnBook,
    },

    mounted() {
      this.all_img = this.cover.concat(this.article_img);
    },

    methods: {
      clickDownLoadPdf(i){
        // console.log(i)
        window.location.href ='../public/ysa_i1a' + (i+1) + '.pdf' ;
      }
    }
  

  }

</script>


<style lang="less" scoped>

.panel {
  // margin-bottom: 40px;
  width: 1200px;
  margin: 20px auto;
  columns: 4;
  column-gap: 40px;
  // overflow: hidden;
}

.panel .box {
  width: 100%;
  margin: 0 0 20px;
  padding: 10px;
  background: #fff;
  // overflow: hidden;
  // break-inside: avoid;
}
.panel .box img {
  max-width: 100%;
}
.panel .box h2 {
  margin: 10px 0 0;
  padding: 0;
  font-size: 18px;
}
.panel .box p {
  margin: 10px 0 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.2em;
  transition: 0.5s;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 17;
  -webkit-box-orient: vertical;
}
.author-dl {
  display: flex;
  justify-content:space-between
}
.author {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 900;
    color: #005eff;
  }
.abstract::before {
  content:  "Abstract. ";
  font-weight: 900;
}

.paper {
  text-align: center;
  .el-image {
    max-width: 600px;
    width:40%;
    margin: 10px 5px;
  }
}

@media (max-width: 1200px) {
  .panel {
    columns: 3;
    width: calc(100% - 40PX);
    box-sizing: border-box;
    padding: 20px 20px 20px 0;
  }
}
@media (max-width: 768px) {
  .panel {
    columns: 2;
  }
}
@media (max-width: 480px) {
  .panel {
    columns: 1;
  }
}

</style>
